<template>
    <div class="container">
      <formMonitoringServices
          payload-process="create"
          :detailed-services-group="detailedServicesGroup"
      />
    </div>
  </template>
  
  <script>
  import formMonitoringServices from "@/components/monitoringServices/form";
  export default {
    name: "ServiceCreate",
    components: {formMonitoringServices},
    data() {
      return {
        detailedServicesGroup: {
          name: "",
          description: "",
          users_ids: [],
          services_ids: []
        }
      };
    },
  }
  </script>
  
  <style scoped>
  
  </style>